@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;600&display=swap');
* { 
  margin: 0;
  padding: 0;
  border-radius: 0;
  outline: 0;
  vertical-align: baseline;
  font-weight: inherit;
font-family: inherit;
font-style: inherit;
font-size: 100%;
  box-sizing: border-box;
}
html{
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  -webkit-user-select:none;
  -khtml-user-select:none;
  -moz-user-select:none;
  -ms-user-select:none;
  user-select:none;

}
body{
  background-color: #757575;
}
.wrapper{
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 1rem;
}
.taplettering{
  font-size: 5rem;
  font-weight: 700;
  color: #3f3e3e;
}
.splitcontainer{
  display: flex;
  text-align: center;
  width: 100%;
  justify-content: space-between;
  padding-inline: 2rem;
  color:#282828;
  max-width: 600px;
}
.displaybpm{
    font-size: 5rem;
    text-align: center;
    line-height: 5rem;
    background-color: #282828;
    color: transparent;
    text-shadow: 2px 2px 3px rgb(152 152 152 / 50%);
    -webkit-background-clip: text;
    -moz-background-clip: text;
    background-clip: text;
    font-style: bold;
    font-weight: 600;
    font-family: 'Orbitron', sans-serif;
}
.secondbpm{
  font-size: 1rem;
  text-align: center;
  background-color: #282828;
  color: transparent;
  text-shadow: 2px 2px 3px rgb(152 152 152 / 50%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  font-style: bold;
  font-weight: 600;
  font-family: 'Orbitron', sans-serif;
}
.sidebutton{
  border-radius: 100%;
  background: #757575;
  box-shadow:  8px 8px 16px #636363, -8px -8px 16px #878787;
  height: 60px;
  width: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
}

.sidebutton:active{
box-shadow:  inset 5px 5px 10px #545454, inset -5px -5px 10px #969696;
}

.modal{
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    padding: 20px;
    background: rgba(124,124,124,0.5);
-webkit-backdrop-filter: blur(6px);
backdrop-filter: blur(6px);
}

.modalhidden{
  display: none;
}
.modalcontainer{
  background-color: #969696;
  max-width: 700px;
  margin: auto;
  padding: 2rem;
  border-radius: 2rem;
  border: solid 2px #868686 ;
}

h1{
  text-align: center;
  font-weight: 800;
  margin-bottom: 1rem;
  font-size: 2rem;
}

ul li {
  margin-bottom: 1rem;
}
.mainbutton{
  border-radius: 100%;
  background: linear-gradient(180deg, black, transparent);
  box-shadow: 10px 10px 20px #545454, -10px -10px 20px #969696;
  height: 300px;
  width: 300px;
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border:none;
  color:#3f3e3e;
  background-color: #565656;
  color: transparent;
  text-shadow: 2px 2px 3px rgb(166 166 166 / 50%);
  -webkit-background-clip: text;
     -moz-background-clip: text;
          background-clip: text;
     font: bold 80px arial, sans-serif;
}

.mainbutton:active{
  box-shadow: inset 5px 5px 10px #545454, inset -5px -5px 10px #969696;
  font: bold 70px arial, sans-serif;
}


.productionlabel{
  font-size: 1rem;
  text-align: center;
  background-color: #282828;
  color: transparent;
  text-shadow: 2px 2px 3px rgb(152 152 152 / 50%);
  -webkit-background-clip: text;
  -moz-background-clip: text;
  background-clip: text;
  font-style: bold;
  font-weight: 600;
  font-family: 'Orbitron', sans-serif;
  margin-top: 2rem;
}
.productionlabel > a{
text-decoration: none;
color: currentcolor;
cursor: pointer;
}